import React from 'react';
import Layout from "../../components/layout";

export default function SevenPrinciplesoftheUniverse() {
    return (
        <Layout>
            <h1>7 Principles of the Universe</h1>
            <ol>
                <li>The universe is mental</li>
                <li>Correspondence (as is above, so below)</li>
                <li>Vibration (nothing rests, everything moves)</li>
                <li>Polarity (everything is dual)</li>
                <li>Rhythm (everything flows out and in)</li>
                <li>Cause and Effect (every cause has its effect)</li>
                <li>Gender (everything has its masculine and feminine)</li>
            </ol>
        </Layout>
    );
}
